import styled from "@emotion/styled";
import React from "react";
import SVG from "~components/Common/SVG";
import useApp from "~hooks/useApp";

const Container = styled.div`
  width: 100%;
`;

const StatusDot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  background: ${({ active }) => (active ? `var(--color-ux-green-100)` : `var(--color-ux-green-100)`)};
  border-radius: 100%;
`;

const ProfileItemActionsWhole = ({ STATUS, header, statusText, isFullCertificate }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { setActiveOverlay } = useApp();

  const disableActionButtons = STATUS === `awaitingQuotation` || STATUS === `awaitingEmailConfirmation`;
  // ---------------------------------------------------------------------------
  // render
  return (
    <Container>
      <div className="profileItemActionsBuyoutFlex">
        <h3 className="b1">{header}</h3>
      </div>

      {statusText && (
        <div className="profileItemActionsBuyoutFlex">
          <StatusDot active={STATUS === `readyToClaim`} />
          <p className="profileItemActionsBuyoutStatus caption">{statusText}</p>
        </div>
      )}

      <div className="profileItemActionsButtons">
        <button
          type="button"
          onClick={() => setActiveOverlay(`NFTOverlayTransferWhole`)}
          className={disableActionButtons ? `profileItemActionsButtonDisabled` : ``}
        >
          <span className="b2">Transfer</span>
          <SVG svg="arrowRight" />
        </button>

        {!isFullCertificate && (
          <button
            type="button"
            onClick={() => setActiveOverlay(`NFTOverlayCreateWholeSellOrder`)}
            className={disableActionButtons ? `profileItemActionsButtonDisabled` : ``}
          >
            <span className="b2">New Sell Offer</span>
            <SVG svg="arrowRight" />
          </button>
        )}

        <button
          type="button"
          onClick={() => setActiveOverlay(`NFTOverlayClaimWhole`)}
          className={disableActionButtons ? `profileItemActionsButtonDisabled` : ``}
        >
          <span className="b2">Claim</span>
          <SVG svg="arrowRight" />
        </button>
      </div>

      {(STATUS === `readyToClaim` || STATUS === `finalisingDetails`) && (
        <div className="profileItemActionsButtons">
          <button
            type="button"
            onClick={() => setActiveOverlay(`NFTOverlayClaimRequestApproveCurrency`)}
            className={STATUS === `finalisingDetails` ? `profileItemActionsButtonDisabled` : ``}
          >
            <span className="b2">Claim Now</span>
            <SVG svg="arrowRight" />
          </button>
        </div>
      )}
    </Container>
  );
};

export default ProfileItemActionsWhole;
