import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useApp, usePurchaseData } from "~hooks";
import { NFTIconNote, NFTCheckout, NFTOverlay } from "~components";
import { formatStablecoin } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient, useWalletClient } from "wagmi";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayPurchaseFragmentFromSellOrder = ({ nft, activeOrder }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const {
    setOverlayCompletionData,
    activeOverlay,
    userData: {
      balances: { maticBalance }
    },
    getConfig
  } = useApp();

  const { data: purchaseData, approved, setApproved, updateAll, reset } = usePurchaseData();

  const { useManage0xTokenAllowance } = blockchainHooks();
  const { refetch } = useManage0xTokenAllowance(
    nft?.enrichedProduct,
    purchaseData?.pricePerFragment * purchaseData?.fragments,
    setApproved,
    false,
    {},
    false,
    activeOverlay === `NFTOverlayPurchaseFragmentFromSellOrder`
  );

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { trader } = useExternalIntegrations();
  const traderSdk = trader(publicClient, walletClient);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const executeApproval = async () => {
    if (!nft?.enrichedProduct?.product || !activeOrder || executing) {
      return () => {};
    }

    setExecuting(true);

    // @externalintegration
    try {
      await traderSdk.approveTokenOrder(activeOrder);
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);
    refetch();

    return null;
  };

  const purchaseFragments = async () => {
    if (!nft?.enrichedProduct?.product || !activeOrder || executing) {
      return () => {};
    }

    setExecuting(true);
    const config = await getConfig();
    try {
      await traderSdk.fillOrder(activeOrder, {}, config);
      reset();

      navigate(`/fragment-purchase-confirmation/${nft.enrichedProduct.product.identifier}/`);
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }
    setExecuting(false);

    return null;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (activeOrder && activeOverlay === `NFTOverlayPurchaseFragmentFromSellOrder`) {
      const pricePerFragment = formatStablecoin(
        (BigInt(activeOrder?.erc20TokenAmount) + BigInt(activeOrder?.order?.fees[0].amount)) / activeOrder?.nftTokenAmount
      );
      updateAll({ fragments: activeOrder?.nftTokenAmount, pricePerFragment });
    }
  }, [activeOrder?.erc20TokenAmount, activeOrder?.order?.fees[0].amount, activeOrder?.nftTokenAmount, activeOverlay]);

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayPurchaseFragmentFromSellOrder" heading="Buy Fragments From Sell Offers" nft={nft} sidebarMode="fragmentFromSellOrder">
      <NFTCheckout
        className="nftOverlayGroup"
        heading="Your Purchase"
        subheading={`Price includes ${nft?.enrichedProduct?.nftData?.takerFee} platform fee.`}
        subheadingVisible={parseFloat(nft?.enrichedProduct?.nftData?.takerFee.split(`%`)[0]) > 0}
        fee={enrichedProduct?.nftData?.taker}
        finalButtonText="Purchase"
        nft={nft}
        data={purchaseData}
        execute={purchaseFragments}
        executeApproval={executeApproval}
        approved={approved}
        onReset={reset}
        approveLoading={executing && !approved}
        actionLoading={executing && approved}
      />
    </NFTOverlay>
  );
};

export default NFTOverlayPurchaseFragmentFromSellOrder;
