import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { formatUnits, zeroAddress } from "viem";
import { Button, NFTFragmentGauge, PriceWidget, LoadingSpinner } from "~components";
import { BLOCK_EXPLORER, checkRightChain, formatTime, thousandCommas, truncateAddress, walletAddressAreEqual } from "~utils/helpers";
import { useAccount } from "wagmi";
import { Link, navigate } from "gatsby";
import { RaffleStatus } from "~dApp/models/raffle/RaffleStatus";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useApp from "~hooks/useApp";
import { useApproveRaffleTicketPurchase, useClaimPrize, useClaimRefund, usePurchaseTicket, useVerificationDeadline } from "~hooks/useRaffle";
import { parseCustomError } from "~utils/error";
import useWindowDimensions from "~hooks/useWindowDimensions";

/** ============================================================================
 * @component
 * @return {node}
 */
const RafflePurchaseInfo = ({ raffle, ticketAvailable, maxTicketSupply, referredUrl, referredCode, loading }) => {
  const {
    setConnectorActive,
    userIsLoggedIn,
    userData: { isConnected, address },
    setWrongChainOverlayData,
    setOverlayCompletionData
  } = useApp();
  const { chain } = useAccount();

  const { windowSize, isMobile } = useWindowDimensions();

  const [ticketAmount, setTicketAmount] = useState(1);
  const [error, setError] = useState(null);
  const [isErc20Approved, setIsErc20Approved] = useState(false);

  const { useGetTokenAllowance, useGetUSDTBalance } = blockchainHooks();

  const { data: allowance, refetch: refetchErc20Allowance } = useGetTokenAllowance(
    raffle.raffleData?.purchaseToken,
    address,
    raffle.raffleData?.raffleTicketPurchase
  );
  const { data: erc20Balance, refetch: refetchErc20Balance } = useGetUSDTBalance();

  useEffect(() => {
    setIsErc20Approved(allowance >= BigInt(raffle.ticketPrice?.result || 0) * BigInt(ticketAmount));
  }, [allowance, raffle.ticketPrice?.result, ticketAmount]);

  const {
    writeAsync: purchaseTicket,
    isLoading: isPurchaseTicketLoading,
    status: purchaseTicketStatus,
    prepareError: purchaseTicketPrepareError,
    isSuccess: isPurchaseTicketSuccess,
    refetch: refetchPurchaseTickets,
    txData: purchaseTicketData
  } = usePurchaseTicket(raffle.raffleData?.raffleTicketPurchase, ticketAmount, referredCode || `000000`);

  const {
    writeAsync: approve,
    isLoading: isErc20ApproveLoading,
    status: erc20ApproveStatus,
    isSuccess: isErc20ApproveSuccess,
    txData: erc20ApproveData
  } = useApproveRaffleTicketPurchase(
    raffle.purchaseToken?.result || zeroAddress,
    raffle.raffleData?.raffleTicketPurchase,
    (BigInt(ticketAmount) * (raffle.ticketPrice?.result || 0n)).toString()
  );

  const {
    writeAsync: claimRefund,
    isLoading: isClaimRefundLoading,
    status: claimRefundStatus,
    prepareError: claimRefundPrepareError,
    isSuccess: isRefundClaimed,
    txData: refundClaimedData
  } = useClaimRefund(raffle.raffleData?.raffleTicketPurchase);

  const {
    writeAsync: claimPrize,
    isLoading: isClaimPrizeLoading,
    status: claimPrizeStatus,
    prepareError: claimPrizePrepareError,
    isSuccess: isPrizeClaimed,
    txData: prizeClaimedData
  } = useClaimPrize(raffle.raffleData?.raffleRewarder);

  const raffleRefetch = useCallback(() => raffle.refetch(), []);

  useEffect(() => {
    if (isRefundClaimed || isPrizeClaimed || isPurchaseTicketSuccess) {
      raffleRefetch();
      refetchErc20Allowance();
      refetchErc20Balance();
    }
    if (isRefundClaimed) {
      setOverlayCompletionData({
        icon: `check`,
        heading: `Refund claimed`,
        transactionUrl: `${BLOCK_EXPLORER}${refundClaimedData.transactionHash}`
      });
    }

    if (isPurchaseTicketSuccess) {
      setOverlayCompletionData({
        icon: `check`,
        heading: `${ticketAmount} Ticket${ticketAmount > 1 ? `s` : ``} Purchased`,
        transactionUrl: `${BLOCK_EXPLORER}${purchaseTicketData.transactionHash}`
      });
    }

    if (isPrizeClaimed) {
      const product = raffle.sanityRaffle?.pagebuilder?.slices?.find((slice) => slice._type === `infoTabs`)?.product || null;
      if (!product) {
        setOverlayCompletionData({
          icon: `check`,
          heading: `Prize Claimed`,
          transactionUrl: `${BLOCK_EXPLORER}${prizeClaimedData.transactionHash}`
        });
      } else {
        navigate(`/raffle-prize-claimed/${product.productGuid}`);
      }
    }
  }, [isRefundClaimed, isPurchaseTicketSuccess, isPrizeClaimed, refundClaimedData, purchaseTicketData, prizeClaimedData, raffleRefetch]);

  useEffect(() => {
    setTicketAmount(1);
    setError(null);
  }, [address]);

  useEffect(() => {
    if (isErc20ApproveSuccess) {
      refetchErc20Allowance();
      refetchPurchaseTickets();
    }
  }, [isErc20ApproveSuccess, erc20ApproveData, refetchErc20Allowance, refetchPurchaseTickets]);

  const endTime = Number(raffle.finishTimestamp?.result || 0n) * 1000;
  const startTime = Number(raffle.startTimestamp?.result || 0n) * 1000;

  const setMaxTicketAmount = () => {
    const maxByMaxCap = ticketAvailable;
    const maxByPersonalMaxCap = Number((raffle.personalMaxTickets?.result || 0n) - (raffle.ticketsPurchased || 0n));
    const maxByBalance = Number((erc20Balance?.value || 0n) / (raffle.ticketPrice?.result || 0n));
    setTicketAmount(Math.min(maxByBalance, maxByMaxCap, maxByPersonalMaxCap));
  };

  const ticketsCaption = () => {
    if ((raffle.ticketsPurchased || 0n) > 0n) {
      switch (raffle.raffleStatus) {
        case RaffleStatus.OnSale:
          return `You own ${raffle.ticketsPurchased || 0} ticket${raffle.ticketsPurchased > 1 ? `s` : ``}. Max personal purchase: ${raffle.personalMaxTickets?.result?.toString() || 0} ticket${raffle.personalMaxTickets?.result > 1 ? `s` : ``}`;
        case RaffleStatus.Failed:
        case RaffleStatus.PersonalMaxCapReached:
        case RaffleStatus.Success:
          return `You own ${raffle.ticketsPurchased || 0} ticket${raffle.ticketsPurchased > 1 ? `s` : ``}`;
        case RaffleStatus.WinnerSelected:
          if (walletAddressAreEqual(address, raffle.raffleData?.winner)) {
            // address === winner
            return `Congratulations from Altr, you're the lucky winner!`;
          }
          return `Unfortunately, you aren't the lucky one this time. Better luck next time!`;

        case RaffleStatus.NotStarted:
        default:
          return `Max purchase ${raffle.personalMaxTickets?.result?.toString() || 0} ticket${raffle.personalMaxTickets?.result > 1 ? `s` : ``}`;
      }
    } else {
      if (raffle.raffleStatus >= RaffleStatus.Success) return `Raffle ticket sale closed`;
      return `Max purchase ${raffle.personalMaxTickets?.result?.toString() || 0} ticket${raffle.personalMaxTickets?.result > 1 ? `s` : ``}`;
    }
  };

  const raffleInfo = () => {
    if (raffle.raffleData?.status === `PRIZE_CLAIMED`) {
      return {
        title: `The winner is:`,
        content: windowSize.width <= 468 ? truncateAddress(raffle.raffleData?.winner, 4, 6) : raffle.raffleData?.winner,
        description: `The winner was selected using Chainlink VRF for a fair and random process. The winner has claimed the prize.`
      };
    }
    switch (raffle.raffleStatus) {
      case RaffleStatus.OnSale:
      case RaffleStatus.PersonalMaxCapReached:
        return {
          description: (
            <span>
              By purchasing tickets, you accept the{" "}
              <Link className="raffleRuleLink" target="_blank" href="https://docs.lucidao.com/dapps/altr-marketplace/raffles/ruleset">
                raffle rules
              </Link>
              .
            </span>
          ),
          title: (
            <span>
              Raffle ends in <br />
              {formatTime(endTime - Date.now(), `DD[d] : HH[h] : mm[m]`)}
            </span>
          )
        };
      case RaffleStatus.Failed:
        return {
          title: `Raffle is Over`,
          description: `The raffle has concluded. We regret to inform you that we did not meet the minimum threshold. If you purchased tickets, you can request a refund below. Thank you for your participation and continued support. Keep an eye out for future opportunities!`
        };
      case RaffleStatus.Success:
        if (!raffle.raffleData?.raffleRewarder)
          return {
            title: `Raffle is Sold Out!`,
            description: `Exciting news! Our raffle tickets have been sold out. Thank you to all participants for making this event a success. Stay tuned for the list of participants and the updated winning odds!`
          };
        if (!raffle.verificationUrl)
          return {
            title: `Raffle is Sold Out!`,
            description: `Exciting news! Our raffle tickets have been sold out. Thank you to all participants for making this event a success. Stay tuned for the list of participants and the updated winning odds!`
          };
        return {
          title: `Raffle is Sold Out!`,
          description: (
            <span>
              The winner will be announced soon. In the meantime, check out this{` `}
              <a target="_blank" className="infoHeadingLink" href={raffle.verificationUrl} rel="noreferrer">
                link
              </a>
              {` `}
              to verify your winning odds.
            </span>
          )
        };
      case RaffleStatus.WinnerSelected:
        return {
          title: `The winner is:`,
          content: windowSize.width <= 468 ? truncateAddress(raffle.raffleData?.winner, 4, 6) : raffle.raffleData?.winner, // Winner
          description: `The winner was selected using Chainlink VRF for a fair and random process. The winner has 10 days to claim the prize. If unclaimed, a new winner will be selected through the VRF process.`
        };
      case RaffleStatus.NotStarted:
        return { title: null, description: `Raffle starts in ${formatTime(startTime - Date.now(), `DD[d] : HH[h] : mm[m]`)}` };
      default:
        return { title: null, description: null };
    }
  };

  const info = raffleInfo();

  return (
    <div className="rafflePurchaseInfo">
      <div className="mainInfo">
        {!!referredCode && (
          <p className="referral">
            <span>Referral code applied:</span>
            <a target="_blank" rel="noreferrer" href={referredUrl}>
              {referredCode}
            </a>
          </p>
        )}
        <div className={loading ? `loadingData info` : `info`}>
          <div className="infoHeading">
            {!!info?.title && <h3>{info.title}</h3>}
            {!!info?.content && <p className={info.content && info.content.startsWith(`0x`) ? `box` : ``}>{info.content}</p>}
            {!!info?.description && <p>{info.description}</p>}
          </div>
          {raffle.raffleStatus === RaffleStatus.OnSale && (
            <dl>
              <dt className="caption">Ticket price:</dt>
              <dd>
                <PriceWidget displayPrice={formatUnits(raffle.ticketPrice?.result || 0n, 6)} color="white" />
              </dd>
            </dl>
          )}
        </div>
      </div>
      <div className="secondaryInfoWrapper">
        {/* <div className="cap">
          <dl>
            <dt>Soft cap:</dt>
            <dd className="reached">
              {Number(raffle.minTickets?.result || 0n)} TICKETS {(raffle.totalTicketsSold?.result || 0n) >= (raffle.minTickets?.result || 0n) ? `✔` : ``}
            </dd>
          </dl>
          <dl>
            <dt>Hard cap:</dt>
            <dd className="reached">
              {Number(raffle.maxTickets?.result || 0n)} TICKETS {(raffle.totalTicketsSold?.result || 0n) >= (raffle.maxTickets?.result || 0n) ? `✔` : ``}
            </dd>
          </dl>
        </div> */}
        {loading && <LoadingSpinner className="loader" width={!isMobile ? 15 : 40} />}
        <div className={loading ? `secondaryInfo loadingData` : `secondaryInfo`}>
          <div className="tickets">
            <NFTFragmentGauge className="raffleFragmentGauge" actual={Number(raffle.totalTicketsSold?.result) || 0} total={maxTicketSupply} />
          </div>
          {raffle.raffleStatus === RaffleStatus.OnSale && userIsLoggedIn && <p className="quantity">Select quantity</p>}
          {!(
            (raffle.raffleStatus === RaffleStatus.Failed && (raffle.ticketsPurchased || 0n) === 0n) ||
            (raffle.raffleStatus === RaffleStatus.OnSale && !userIsLoggedIn)
          ) && (
            <div className="ticketCaption">
              <strong
                className={cn(
                  `caption`,
                  (raffle.ticketsPurchased || 0n) > 0n ? `ticketsCaptionPurchased` : ``,
                  raffle.raffleStatus === RaffleStatus.WinnerSelected
                    ? walletAddressAreEqual(raffle.raffleData?.winner, address)
                      ? `ticketsCaptionPurchased`
                      : `ticketsCaptionLost`
                    : ``,
                  raffle.raffleData?.status === `PRIZE_CLAIMED` ? `ticketsCaptionClaimed` : ``
                )}
              >
                {ticketsCaption()}
              </strong>
              {address && userIsLoggedIn && raffle.raffleStatus === RaffleStatus.OnSale && (
                <span className="balance caption">
                  Balance: {thousandCommas(Number(erc20Balance.formatted).toFixed(2))}
                  <Button
                    variant="text"
                    style={{ color: `var(--color-black)` }}
                    onClick={() => {
                      setMaxTicketAmount();
                    }}
                  >
                    MAX
                  </Button>
                </span>
              )}
            </div>
          )}
          {raffle.raffleStatus === RaffleStatus.OnSale && userIsLoggedIn && (
            <div className="ticketSelector">
              <div className="input">
                <Button
                  variant="secondary"
                  colorTheme="dark"
                  onClick={() => {
                    if (ticketAmount <= 1) {
                      setError(`Cannot buy less than one ticket at a time`);
                      return;
                    }
                    setError(null);
                    setTicketAmount((prev) => prev - 1);
                  }}
                >
                  -
                </Button>
                <input
                  type="number"
                  value={ticketAmount}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value < 1) {
                      setTicketAmount(1);
                      setError(`Cannot buy less than one ticket at a time`);
                      return;
                    }
                    if (value > ticketAvailable) {
                      setMaxTicketAmount();
                      setError(`Hard cap reached`);
                      return;
                    }
                    if (BigInt(value) + (raffle.ticketsPurchased || 0n) > (raffle.personalMaxTickets?.result || 0n)) {
                      setMaxTicketAmount();
                      setError(`Personal hard cap reached`);
                      return;
                    }
                    if ((erc20Balance?.value || 0n) < BigInt(value) * (raffle.ticketPrice?.result || 0n)) {
                      setMaxTicketAmount();
                      setError(`Not enough USDt`);
                      return;
                    }
                    setError(null);
                    setTicketAmount(value);
                  }}
                />
                <Button
                  variant="secondary"
                  colorTheme="dark"
                  onClick={() => {
                    if ((erc20Balance?.value || 0n) < BigInt(ticketAmount) * (raffle.ticketPrice?.result || 0n)) {
                      setError(`Not enough USDt`);
                      return;
                    }
                    if (BigInt(ticketAmount) + (raffle.ticketsPurchased || 0n) >= (raffle.personalMaxTickets?.result || 0n)) {
                      setError(`Personal hard cap reached`);
                      return;
                    }
                    if (ticketAmount >= ticketAvailable) {
                      setError(`Hard cap reached`);
                      return;
                    }
                    setError(null);
                    setTicketAmount((prev) => prev + 1);
                  }}
                >
                  +
                </Button>
              </div>
              <dl>
                <dt className="caption">Total:</dt>
                <dd>
                  <PriceWidget displayPrice={formatUnits((raffle.ticketPrice?.result || 0n) * BigInt(ticketAmount) || 0n, 6)} color="black" fontClass="h2" />
                </dd>
              </dl>
            </div>
          )}
          {userIsLoggedIn && raffle.raffleStatus === RaffleStatus.OnSale && !!error && <p className="error caption">{error}</p>}
          {userIsLoggedIn && raffle.raffleStatus === RaffleStatus.OnSale && !error && !!purchaseTicketPrepareError && isErc20Approved && (
            <p className="error caption">{parseCustomError(purchaseTicketPrepareError)}</p>
          )}
          {userIsLoggedIn && raffle.raffleStatus === RaffleStatus.Failed && (raffle.ticketsPurchased || 0n) > 0n && !!claimRefundPrepareError && (
            <p className="error capture">{parseCustomError(claimRefundPrepareError)}</p>
          )}
          <div className="buttons flexGrid">
            {/* {raffle.raffleStatus === RaffleStatus.NotStarted && (
            <RaffleActionWaitlist guid={raffle.sanityRaffle?.pagebuilder?.slices?.find((slice) => slice._type === `infoTabs`)?.product?.productGuid} />
          )} */}
            {raffle.raffleStatus !== RaffleStatus.NotStarted && (!isConnected || !userIsLoggedIn) ? (
              <Button
                fluid
                onClick={() => {
                  setConnectorActive(true);
                }}
                variant="primaryTall"
                colorTheme="dark"
              >
                Sign In
              </Button>
            ) : (
              <>
                {raffle.raffleStatus === RaffleStatus.Failed && (raffle.ticketsPurchased || 0n) > 0n && (
                  <Button
                    fluid
                    className={cn((isClaimRefundLoading || claimRefundStatus === `pending`) && `loading`, isRefundClaimed && `success`)}
                    onClick={async () => {
                      if (!checkRightChain(chain?.id, setWrongChainOverlayData)) return;
                      claimRefund();
                    }}
                    variant="primaryTall"
                    colorTheme="dark"
                    disabled={
                      raffle.ticketsPurchased === 0n || isRefundClaimed || isClaimRefundLoading || claimRefundStatus === `pending` || !!claimRefundPrepareError
                    }
                  >
                    {isRefundClaimed ? `Refunded` : `Refund`}
                  </Button>
                )}
                {raffle.raffleStatus === RaffleStatus.OnSale && (
                  <>
                    <Button
                      className={cn((isErc20ApproveLoading || erc20ApproveStatus === `pending`) && `loading`, isErc20Approved && `success`)}
                      onClick={async () => {
                        if (!checkRightChain(chain?.id, setWrongChainOverlayData)) return;
                        approve();
                      }}
                      fluid
                      variant="primaryTall"
                      colorTheme="dark"
                      disabled={ticketAvailable === 0 || isErc20Approved || isErc20ApproveLoading || erc20ApproveStatus === `pending`}
                    >
                      {isErc20Approved ? `Approved` : `Approve`}
                    </Button>
                    <Button
                      className={cn((isPurchaseTicketLoading || purchaseTicketStatus === `pending`) && `loading`)}
                      onClick={async () => {
                        if (!checkRightChain(chain?.id, setWrongChainOverlayData)) return;
                        purchaseTicket();
                      }}
                      fluid
                      variant="feature"
                      colorTheme="dark"
                      disabled={
                        ticketAvailable === 0 ||
                        !isErc20Approved ||
                        isPurchaseTicketLoading ||
                        purchaseTicketStatus === `pending` ||
                        !!purchaseTicketPrepareError
                      }
                    >
                      Buy
                    </Button>
                  </>
                )}
                {raffle.raffleStatus === RaffleStatus.WinnerSelected &&
                  walletAddressAreEqual(raffle.raffleData?.winner, address) &&
                  raffle.raffleData?.status !== `PRIZE_CLAIMED` && (
                    <Button
                      fluid
                      className={cn((isClaimPrizeLoading || claimPrizeStatus === `pending`) && `loading`, isPrizeClaimed && `success`)}
                      onClick={async () => {
                        if (!checkRightChain(chain?.id, setWrongChainOverlayData)) return;
                        claimPrize();
                      }}
                      variant="primaryTall"
                      colorTheme="dark"
                      disabled={isPrizeClaimed || isClaimPrizeLoading || claimPrizeStatus === `pending` || !!claimPrizePrepareError}
                    >
                      {isPrizeClaimed ? `Claimed` : `Claim your prize`}
                    </Button>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RafflePurchaseInfo;
