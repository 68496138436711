import React, { useEffect, useState } from "react";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { checkRightChain, checkUserIsLoggedIn, genericErrorCallback, walletAddressAreEqual } from "~utils/helpers";
import NFTActivityList from "../NFTActivityList";
import useApp from "~hooks/useApp";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { zeroAddress } from "viem";

const NFTSellOrdersList = ({ id, enrichedProduct, nftStatus, page = `product`, setTabsLength, activeId }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const {
    userData,
    setOrderCanceled,
    orderCanceled,
    activeOverlay,
    setActiveOverlay,
    setOverlayActivityData,
    setOverlayProcessingData,
    setWrongChainOverlayData,
    setOverlayCompletionData,
    userIsLoggedIn,
    setConnectorActive
  } = useApp();
  const { api, trader } = useExternalIntegrations();
  const { chain } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const traderSdk = trader(publicClient, walletClient);
  const { useGetNftOwnership, useGetFractionsBalance } = blockchainHooks();
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [nftSellOrders, setNftSellOrders] = useState();

  // ---------------------------------------------------------------------------
  // methods
  const isSoldOut = nftStatus?.is(`SoldOut`);

  const cancelOrder = async (row) => {
    try {
      setOverlayProcessingData({
        icon: `load`,
        text: `Processing. Please do not close this page.`
      });
      await traderSdk.cancelOrder(row);
      setOrderCanceled(!orderCanceled);
    } catch (error) {
      handleError(error, setOverlayCompletionData, userData.balances.maticBalance?.value);
      console.log(error);
    }
    setOverlayProcessingData(null);
  };

  const handleActivityItemClick = (row) => {
    if (!checkUserIsLoggedIn(userIsLoggedIn, setOverlayCompletionData, setConnectorActive)) return;
    if (!checkRightChain(chain?.id, setWrongChainOverlayData)) return;
    const isOwner = walletAddressAreEqual(userData?.address, row?.order?.maker);
    if (isOwner) {
      cancelOrder(row);
    } else {
      setOverlayActivityData({
        owner: row,
        order: row,
        fragments: row?.nftTokenAmount
      });
      if (isSoldOut) {
        setActiveOverlay(`NFTOverlayPurchaseWholeFromSellOrder`);
      } else {
        setActiveOverlay(`NFTOverlayPurchaseFragmentFromSellOrder`);
      }
    }
  };

  const handleSecondaryActivityItemClick = () => {
    if (!checkRightChain(chain?.id, setWrongChainOverlayData)) return;
    if (isSoldOut) {
      setActiveOverlay(`NFTOverlayCreateWholeSellOrder`);
    } else {
      setActiveOverlay(`NFTOverlayCreateFragmentSellOrder`);
    }
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  const { data: fractionsBalance } = useGetFractionsBalance(enrichedProduct?.nftData, userData?.address);

  const isNftOwner = useGetNftOwnership(enrichedProduct?.nftData);

  useEffect(() => {
    const setOrders = async () => {
      const nftType = nftStatus?.is(`SoldOut`) ? `ERC721` : `ERC1155`;

      const args = [enrichedProduct?.nftData, nftType];
      if (page === `collection`) {
        args.push(userData?.address);
      }
      const orders = await api.getNftOrders(...args);

      if (orders?.orders) {
        const selectedOrders = orders.orders.reduce((sellOrders, order) => {
          if (!order?.order?.taker || walletAddressAreEqual(order.order.taker, zeroAddress) || walletAddressAreEqual(order.order.taker, userData?.address)) {
            if (order.sellOrBuyNft === `sell`) {
              sellOrders.push(order);
            }
          }
          return sellOrders;
        }, []);
        selectedOrders.sort((a, b) => a.erc20TokenAmount / a.nftTokenAmount - b.erc20TokenAmount / b.nftTokenAmount);

        setNftSellOrders(selectedOrders);
        setTabsLength(id, selectedOrders.length);
      }
    };
    setOrders().catch(genericErrorCallback);
  }, [
    enrichedProduct?.nftData?.nftCollectionInfo?.collectionAddress,
    enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress,
    enrichedProduct?.nftData?.tokenId,
    enrichedProduct?.nftData?.saleId,
    nftStatus?.status,
    userData?.address,
    activeOverlay,
    orderCanceled
  ]);

  // ---------------------------------------------------------------------------
  // render

  if (activeId !== `Sell Offers`) {
    return null;
  }

  const secondaryAction = isNftOwner || fractionsBalance > 0 ? `Create New Sell Offer` : null;

  return (
    <NFTActivityList
      nftStatus={nftStatus}
      id="Sell Offers"
      rows={nftSellOrders}
      buttonLabel="Buy"
      alternativeButtonLabel="Cancel Offer"
      handleActivityItemClick={handleActivityItemClick}
      handleSecondaryActivityItemClick={handleSecondaryActivityItemClick}
      secondaryAction={secondaryAction}
    />
  );
};

export default NFTSellOrdersList;
