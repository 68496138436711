/* eslint-disable react/no-danger */
import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { CategoryTab, Link, PriceChart } from "~components";
import Button from "~components/Common/Button";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  display: flex;

  .infoTabsDesktop {
    &TabContainer {
      width: 220px;
      flex-shrink: 0;
      margin-right: 2rem;

      > * {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &ContentContainer {
      flex-grow: 1;
      padding: 1rem 0;
    }

    &Heading {
      margin-bottom: 30px;
    }

    &Certificate {
      text-align: center;
    }

    &AuthCertificate {
      margin-top: 10px;

      &Button {
        margin: auto;
        max-width: 1000px;
      }
    }

    &Partition {
      margin-bottom: 1.5rem;
      border-top: 1px solid var(--color-mono-40);
    }

    &InfoRow {
      &Heading {
        margin-bottom: 1.5rem;
        margin-top: 2.5rem;
      }

      &LabelText {
        display: flex;
        align-items: center;
        border-top: 1px solid var(--color-mono-40);
        padding: 8px 0;
      }

      &Label {
        color: var(--color-mono-60);
        width: 200px;
        margin-right: 1rem;
        flex-shrink: 0;
      }
    }

    &AboutContent {
      display: flex;
      gap: 160px;
    }
  }
`;

/** ============================================================================
 * @component
 */
const InfoTabsDesktop = ({ basicInfo, certificate, authCertificate, aboutText, saleDataRows, saleDataDescription, lucidProduct }) => {
  const [activeTab, setActiveTab] = useState(`basicInfo`);
  const displayInfo = basicInfo || lucidProduct?.product?.attributes;
  const displayAbout =
    aboutText?.reduce((acc, curr) => acc + (acc != `` ? `<br/><br/>` : ``) + curr.children[0].text, ``) || lucidProduct?.product?.description?.values[`en-ES`];

  return (
    <Container>
      <div className="infoTabsDesktopTabContainer">
        <CategoryTab tabNumber="01" tabTitle="Basic Information" selected={activeTab === `basicInfo`} onClick={() => setActiveTab(`basicInfo`)} />
        <CategoryTab tabNumber="02" tabTitle="About This Product" selected={activeTab === `about`} onClick={() => setActiveTab(`about`)} />
        <CategoryTab tabNumber="03" tabTitle="Price Index" selected={activeTab === `priceIndex`} onClick={() => setActiveTab(`priceIndex`)} />
        <CategoryTab tabNumber="04" tabTitle="Authenticity Certificate" selected={activeTab === `certificate`} onClick={() => setActiveTab(`certificate`)} />
        <CategoryTab tabNumber="05" tabTitle="Blockchain Data" selected={activeTab === `blockchainData`} onClick={() => setActiveTab(`blockchainData`)} />
      </div>

      <div className="infoTabsDesktopContentContainer">
        {activeTab === `basicInfo` && (
          <article>
            <h3 className="h3 infoTabsDesktopHeading">Basic Information</h3>

            <ul>
              {displayInfo?.map((item, i) => (
                <li key={`pdp-attribute-${i}`}>
                  <div className="infoTabsDesktopInfoRowLabelText">
                    <h5 className="caption infoTabsDesktopInfoRowLabel">{item.label}</h5>
                    <div className="tag infoTabsDesktopInfoRowText">{item.text || item.value}</div>
                  </div>
                </li>
              ))}
            </ul>
          </article>
        )}

        {activeTab === `about` && (
          <article>
            <h3 className="h3 infoTabsDesktopHeading">About This Product</h3>
            <div className="infoTabsDesktopPartition" />

            <div className="infoTabsDesktopAboutContent">
              <h4 className="tag">Summary</h4>

              {/* todo: multi-language? (it-IT) */}
              {displayAbout && (
                <div
                  className="b2"
                  dangerouslySetInnerHTML={{
                    __html: displayAbout
                  }}
                />
              )}
            </div>
          </article>
        )}

        {activeTab === `priceIndex` && (
          <article>
            <h3 className="h3 infoTabsDesktopHeading">Price Index</h3>
            <h5 className="h5">
              {saleDataDescription ||
                `This price index has been compiled by our Oracles. Altr works with
                world-renowned experts who know and understand the market, both
                dealer sales and auction, ensuring that you can access accurate
                and up-to-date information on item values.`}
            </h5>

            <PriceChart saleDataRows={saleDataRows} />
          </article>
        )}

        {activeTab === `certificate` && (
          <article>
            <h3 className="h3 infoTabsDesktopHeading">Authenticity Certificate</h3>
            <div className="infoTabsDesktopCertificate">
              {certificate.map((image, i) => (
                <GatsbyImage key={i} style={{ maxWidth: 1000 }} image={image?.asset?.gatsbyImageData} alt="Certificate of authenticity" />
              ))}
            </div>
            {authCertificate && (
              <div className="infoTabsDesktopAuthCertificate">
                <Button className="infoTabsDesktopAuthCertificateButton" colorTheme="dark" fluid href={`${authCertificate.asset.url}`}>
                  View Certificate
                </Button>
              </div>
            )}
          </article>
        )}

        {activeTab === `blockchainData` && (
          <article>
            <h3 className="h3 infoTabsDesktopHeading">Blockchain Data</h3>

            {(lucidProduct?.nftData?.tokenId && (
              <div className="infoTabsDesktopInfoRowLabelText">
                <h5 className="caption infoTabsDesktopInfoRowLabel">NFT ID</h5>
                <div className="tag infoTabsDesktopInfoRowText">{lucidProduct.nftData.tokenId}</div>
              </div>
            )) ||
              `NFT ID will be available on sale date.`}

            {lucidProduct?.nftData?.nftCollectionInfo?.collectionAddress && (
              <div className="infoTabsDesktopInfoRowLabelText">
                <h5 className="caption infoTabsDesktopInfoRowLabel">NFT Collection</h5>
                <div className="tag infoTabsDesktopInfoRowText">
                  <Link
                    css={css`
                      &:hover {
                        text-decoration: underline;
                        color: var(--color-primary-orange-100);
                      }
                    `}
                    to={lucidProduct.nftData.contractExplorerUrl}
                  >
                    {lucidProduct.nftData.nftCollectionInfo.collectionAddress}
                  </Link>
                </div>
              </div>
            )}
          </article>
        )}
      </div>
    </Container>
  );
};

export default InfoTabsDesktop;
