/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { CategoryTab, Link, PriceChart } from "~components";
import Button from "~components/Common/Button";

/** ============================================================================
 * @css
 */
const Expander = styled.div`
  margin-bottom: 1rem;
  overflow: hidden;
  transition: 0.4s height;
  height: ${({ isOpen, contentHeight }) => (isOpen ? `${contentHeight}px` : `0px`)};
`;

const Content = styled.div`
  padding: 1.5rem 0;

  .infoTabsMobile {
    &SummaryTag {
      margin-bottom: 0.75rem;
    }

    &AuthCertificateButton {
      margin-top: 10px;
    }

    &InfoRow {
      &Heading {
        margin-bottom: 1.25rem;
        margin-top: 2.5rem;
      }
      &LabelText {
        border-bottom: 1px solid var(--color-mono-40);
        margin-bottom: 1.25rem;
      }
      &Label {
        color: var(--color-mono-60);
        margin-bottom: 4px;
      }
      &Text {
        margin-bottom: 8px;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const InfoTabsMobile = ({ basicInfo, certificate, authCertificate, aboutText, saleDataRows, saleDataDescription, lucidProduct }) => {
  const initialOpenTabs = {
    basicInfo: false,
    about: false,
    priceIndex: false,
    certificate: false
  };
  const [openTabs, setOpenTabs] = useState(initialOpenTabs);
  const [basicInfoHeight, setBasicInfoHeight] = useState();
  const [aboutHeight, setAboutHeight] = useState();
  const [priceIndexHeight, setPriceIndexHeight] = useState();
  const [certificateHeight, setCertificateHeight] = useState();
  const [blockchainDataHeight, setBlockchainDataHeight] = useState();

  const basicInfoRef = useRef(null);
  const aboutRef = useRef(null);
  const priceIndexRef = useRef(null);
  const certificateRef = useRef(null);
  const blockchainDataRef = useRef(null);

  const calculateContentHeight = () => {
    setBasicInfoHeight(basicInfoRef?.current?.offsetHeight || 0);
    setAboutHeight(aboutRef?.current?.offsetHeight || 0);
    setPriceIndexHeight(priceIndexRef?.current?.offsetHeight || 0);
    setCertificateHeight(certificateRef?.current?.offsetHeight || 0);
    setBlockchainDataHeight(blockchainDataRef?.current?.offsetHeight || 0);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    calculateContentHeight();

    window.addEventListener(`resize`, calculateContentHeight);

    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  // ---------------------------------------------------------------------------
  // render

  const displayInfo = basicInfo || lucidProduct?.product?.attributes;
  const displayAbout =
    aboutText?.reduce((acc, curr) => acc + (acc != `` ? `<br/><br/>` : ``) + curr.children[0].text, ``) || lucidProduct?.product?.description?.values[`en-ES`];

  return (
    <div>
      {/* 01 Basic Info */}
      <CategoryTab
        tabNumber="01"
        tabTitle="Basic Information"
        selected={openTabs.basicInfo}
        onClick={() => setOpenTabs((prev) => ({ ...prev, basicInfo: !prev.basicInfo }))}
        mobile
      />

      <Expander isOpen={openTabs?.basicInfo} contentHeight={basicInfoHeight}>
        <Content ref={basicInfoRef}>
          <ul>
            {displayInfo.map((item, i) => (
              <li key={`pdp-attribute-xs-${i}`}>
                <div className="infoTabsMobileInfoRowLabelText">
                  <h5 className="caption infoTabsMobileInfoRowLabel">{item.label}</h5>
                  <p className="tag infoTabsMobileInfoRowText">{item.text || item.value}</p>
                </div>
              </li>
            ))}
          </ul>
        </Content>
      </Expander>

      {/* 02 About */}
      <CategoryTab
        tabNumber="02"
        tabTitle="About This Product"
        selected={openTabs.about}
        onClick={() => setOpenTabs((prev) => ({ ...prev, about: !prev.about }))}
        mobile
      />

      <Expander isOpen={openTabs.about} contentHeight={aboutHeight}>
        <Content ref={aboutRef}>
          <div className="tag infoTabsMobileSummaryTag">Summary</div>

          {/* todo: multi-language? (it-IT) */}
          {displayAbout && (
            <div
              className="b2"
              dangerouslySetInnerHTML={{
                __html: displayAbout
              }}
            />
          )}
        </Content>
      </Expander>

      {/* 03 Price Index */}
      <CategoryTab
        tabNumber="03"
        tabTitle="Price Index"
        selected={openTabs.priceIndex}
        onClick={() => setOpenTabs((prev) => ({ ...prev, priceIndex: !prev.priceIndex }))}
        mobile
      />

      <Expander isOpen={openTabs.priceIndex} contentHeight={priceIndexHeight}>
        <Content ref={priceIndexRef}>
          <h5 className="h5">
            {saleDataDescription ||
              `This price index has been compiled by our Oracles. Altr works with
              world-renowned experts who know and understand the market, both
              dealer sales and auction, ensuring that you can access accurate
              and up-to-date information on item values.`}
          </h5>
          <PriceChart saleDataRows={saleDataRows} />
        </Content>
      </Expander>

      {/* 04 Authenticity Certificate */}
      <CategoryTab
        tabNumber="04"
        tabTitle="Authenticity Certificate"
        selected={openTabs.certificate}
        onClick={() => setOpenTabs((prev) => ({ ...prev, certificate: !prev.certificate }))}
        mobile
      />

      <Expander isOpen={openTabs.certificate} contentHeight={certificateHeight}>
        <Content ref={certificateRef}>
          {certificate.map((image, i) => (
            <GatsbyImage key={i} image={image?.asset?.gatsbyImageData} alt="Certificate of authenticity" />
          ))}
          {authCertificate && (
            <div className="infoTabsMobileAuthCertificateButton">
              <Button colorTheme="dark" fluid href={`${authCertificate.asset.url}`}>
                View Certificate
              </Button>
            </div>
          )}
        </Content>
      </Expander>

      {/* 05 Blockchain Data */}
      <CategoryTab
        tabNumber="05"
        tabTitle="Blockchain Data"
        selected={openTabs.blockchainData}
        onClick={() =>
          setOpenTabs((prev) => ({
            ...prev,
            blockchainData: !prev.blockchainData
          }))
        }
        mobile
      />
      <Expander isOpen={openTabs.blockchainData} contentHeight={blockchainDataHeight}>
        <Content ref={blockchainDataRef}>
          <>
            {(lucidProduct?.nftData?.tokenId && (
              <div className="infoTabsMobileInfoRowLabelText">
                <h5 className="caption infoTabsMobileInfoRowLabel">NFT ID</h5>
                <div className="tag infoTabsMobileInfoRowText">{lucidProduct.nftData.tokenId}</div>
              </div>
            )) ||
              `NFT ID will be available on sale date.`}

            {lucidProduct?.nftData?.nftCollectionInfo?.collectionAddress && (
              <div className="infoTabsMobileInfoRowLabelText">
                <h5 className="caption infoTabsMobileInfoRowLabel">NFT Collection</h5>
                <div className="tag infoTabsMobileInfoRowText">
                  <Link
                    css={css`
                      &:hover {
                        text-decoration: underline;
                        color: var(--color-primary-orange-100);
                      }
                    `}
                    to={lucidProduct.nftData.contractExplorerUrl}
                  >
                    {lucidProduct.nftData.nftCollectionInfo.collectionAddress}
                  </Link>
                </div>
              </div>
            )}
          </>
        </Content>
      </Expander>
    </div>
  );
};

export default InfoTabsMobile;
