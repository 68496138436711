import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Link, navigate } from "gatsby";
import { SVG, Hamburger, MenuItem, Button } from "~components";
import { lendWebsite, lucidaoWebsite } from "~utils/helpers";
import { useApp } from "~hooks";

/** ============================================================================
 * @component
 *
 * @return {node}
 */
const Navbar = () => {
  const [active, setActive] = useState(false);
  const [contentHeight, setContentHeight] = useState(60);
  const contentRef = useRef();

  const { pathname, connectorActive, setConnectorActive, userIsLoggedIn } = useApp();
  const isProfile = pathname?.includes(`profile`);

  const calculateContentHeight = () => {
    const height = contentRef.current.offsetHeight;
    setContentHeight(height);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  useEffect(() => {
    setActive(false);
  }, [pathname]);

  useEffect(() => {
    document.addEventListener(`scroll`, () => setActive(false), {
      passive: true
    });
    return () =>
      document.removeEventListener(`scroll`, () => setActive(false), {
        passive: true
      });
  }, []);

  const handleConnectWallet = () => {
    setConnectorActive(!connectorActive);
  };

  return (
    <header id="navbar" className="frosted-glass-light">
      <div className="bar">
        <h1>
          <Link to="/">
            <SVG svg="logo" className="logo" />
            <span className="visually-hidden">ALTR</span>
          </Link>
        </h1>
        <div className={cn(`account`, isProfile && `isProfile`)}>
          {!isProfile && (
            <Button
              variant="secondary"
              colorTheme="dark"
              iconLeft={userIsLoggedIn ? `user` : null}
              onClick={() => (!userIsLoggedIn ? handleConnectWallet() : navigate(`/profile`))}
            >
              {userIsLoggedIn ? `Profile` : `Sign In`}
            </Button>
          )}
          {isProfile && userIsLoggedIn && <strong>Signed In</strong>}
        </div>
        <Hamburger active={active} setActive={setActive} />
      </div>

      <div className="navWrapper hiddenScroll" style={{ height: `${active ? contentHeight : 0}px` }}>
        <nav ref={contentRef} style={{ opacity: active ? 1 : 0 }}>
          <section className="navCategory">
            <h2 className="caption">Altr</h2>

            <ul className="h3">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/how-it-works">How it works</Link>
              </li>
              <li>
                <Link to="/oracles">Oracles</Link>
              </li>
              <MenuItem variant="text" colorTheme="dark" text="Lucidao" url={lucidaoWebsite} />
            </ul>
          </section>

          <section className="navCategory">
            <h2 className="caption">Products</h2>

            <ul className="h3">
              <li>
                <Link to="/marketplace">Marketplace</Link>
              </li>
              <li>
                <Link to="/drops">Drops</Link>
              </li>
            </ul>
          </section>

          <section className="navCategory">
            <h2 className="caption">Services</h2>

            <ul className="h3">
              <li>
                <Link to="/raffles/daytona">Raffle</Link>
              </li>
              <li>
                <Link to="/profile/digitise">Digitise</Link>
              </li>
              <MenuItem variant="text" colorTheme="dark" text="RWA Lending" url={lendWebsite} />
            </ul>
          </section>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
