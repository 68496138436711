import React, { useEffect, useState } from "react";
import { useApp, usePurchaseData } from "~hooks";
import { NFTIconNote, NFTCheckout, NFTOverlay } from "~components";
import { formatStablecoin } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient, useWalletClient } from "wagmi";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlaySellFragmentFromBuyOrder = ({ nft, activeOrder }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const {
    setOverlayCompletionData,
    activeOverlay,
    userData: {
      balances: { maticBalance }
    },
    getConfig
  } = useApp();

  const { data: purchaseData, approved, setApproved, updateAll, reset } = usePurchaseData();

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { trader } = useExternalIntegrations();
  const traderSdk = trader(publicClient, walletClient);

  const { useManage0xNftAllowance } = blockchainHooks();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  const { refetch } = useManage0xNftAllowance(
    nft?.enrichedProduct?.nftData,
    `ERC1155`,
    purchaseData?.fragments,
    setApproved,
    activeOverlay === `NFTOverlaySellFragmentFromBuyOrder`
  );

  useEffect(() => {
    if (activeOrder && activeOverlay === `NFTOverlaySellFragmentFromBuyOrder`) {
      const pricePerFragment = formatStablecoin(BigInt(activeOrder?.erc20TokenAmount) / BigInt(activeOrder?.nftTokenAmount));
      const fragments = activeOrder?.nftTokenAmount;
      updateAll({ fragments, pricePerFragment });
    }
  }, [activeOrder?.erc20TokenAmount, activeOrder?.nftTokenAmount, activeOverlay, executing]);

  // ---------------------------------------------------------------------------
  // methods

  const executeApproval = async () => {
    if (!nft?.enrichedProduct?.nftData || !activeOrder || executing) {
      return () => {};
    }

    setExecuting(true);
    // @externalintegration
    try {
      await traderSdk.approveTokenOrder(activeOrder, { fractionsAmountOverride: purchaseData?.fragments });
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);

    return null;
  };

  const sellFragments = async () => {
    if (!nft?.enrichedProduct?.product || !activeOrder || executing) {
      return () => {};
    }

    setExecuting(true);
    const config = await getConfig();

    // @externalintegration
    try {
      await traderSdk.fillOrder(activeOrder, {}, config);
      reset();

      setOverlayCompletionData({
        icon: `check`,
        heading: `Deal closed successfully`,
        body: `Congratulation on accepting the offer and closing the deal.`
      });
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);

    reset();
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlaySellFragmentFromBuyOrder" heading="Sell Fragments From Buy Offers" nft={nft} sidebarMode="fragmentFromBuyOrder">
      <NFTCheckout
        className="nftOverlayGroup"
        heading="Sell Offer"
        finalButtonText="Sell"
        subheading={`Total includes a ${nft?.enrichedProduct?.nftData?.takerFee} platform fee, deducted upon order fulfillment.`}
        subheadingVisible={parseFloat(nft?.enrichedProduct?.nftData?.takerFee?.split(`%`)[0]) > 0}
        fee={nft?.enrichedProduct?.nftData?.takerFee}
        nft={nft}
        data={purchaseData}
        execute={sellFragments}
        executeApproval={executeApproval}
        approved={approved}
        onReset={reset}
        refetch={refetch}
        approveLoafing={executing && !approved}
        actionLoading={executing && approved}
      />

      {!approved && (
        <div className="nftOverlayGroup">
          <NFTIconNote
            background="rgba(255, 255, 255, 0.4)"
            fontClass="caption"
            svg="alert"
            text="By clicking this button, you are authorizing the smart contract to initiate a blockchain transaction and process funds to the seller on your behalf. smart contract to transfer fragments to the seller on your behalf."
          />
        </div>
      )}
    </NFTOverlay>
  );
};

export default NFTOverlaySellFragmentFromBuyOrder;
