import React, { useEffect, useMemo, useState } from "react";
import { stakingWebsite } from "~utils/helpers";
import { useReadContracts } from "wagmi";
import cn from "classnames";
import SVG from "../SVG";
import Button from "../Button";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useApp from "~hooks/useApp";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";
import { licenseManager } from "~dApp/abi";

const LucidaoRebate = ({ className, nftData, order, queryEnabled = false, parentLoading = false }) => {
  const [rebate, setRebate] = useState({ tier: undefined, percentage: undefined, value: undefined });

  const expanded = useMemo(
    () => Object.values(rebate).reduce((acc, curr) => acc && !!curr && typeof curr !== `undefined`, true),
    [rebate.tier, rebate.percentage, rebate.value]
  );

  const {
    userData: { address }
  } = useApp();
  const { useGetLicenseManager, useGetStakerInfo } = blockchainHooks();

  const { isPending: isLicenseManagerPending, data: licenseManagerAddress } = useGetLicenseManager(nftData, queryEnabled);
  const contracts = [
    { address: licenseManagerAddress, abi: licenseManager, functionName: `getDiscount`, args: [address] },
    { address: licenseManagerAddress, abi: licenseManager, functionName: `getStakingTiers`, args: [] },
    { address: licenseManagerAddress, abi: licenseManager, functionName: `staking`, args: [] }
  ];
  const { isPending: isDiscountPending, data } = useReadContracts({ contracts, query: { enabled: !!licenseManagerAddress } });
  const { isPending: isStakerInfoPending, data: stakerInfo } = useGetStakerInfo(data?.[2]?.result, address);

  const {
    price: { fees },
    makeDisplayPrice
  } = useParseTraderOrder(order.order);

  useEffect(() => {
    let discount;
    let tiers;
    if (Array.isArray(data)) {
      [discount, tiers] = data;
    }

    setRebate({
      tier: tiers?.result?.findIndex((tier) => stakerInfo?.[3] >= tier) + 1,
      percentage: (Number(discount?.result || 0) / 100).toFixed(1),
      value: makeDisplayPrice(((fees || 0n) * (discount?.result || 0n)) / 20000n)
    });
  }, [data?.[0], data?.[1]?.length, fees, stakerInfo?.[3]]);

  const isPending = isLicenseManagerPending || isDiscountPending || isStakerInfoPending || parentLoading;

  return (
    <div className={cn(`lucidaoRebate`, className)}>
      {isPending || expanded ? (
        <>
          <span className="lucidaoRebateHeading">
            <p className="caption">Staking Perks</p>
            <SVG svg="lucidao" />
          </span>
          <ul className={isPending ? `loading` : ``}>
            <li>
              <div className="frosted-glass-light">
                <span>0{rebate.tier}</span>
                <h3>TIER</h3>
              </div>
            </li>
            <li>
              <div className="frosted-glass-light">
                <span>{rebate.percentage}%</span>
                <h3>REBATE %</h3>
              </div>
            </li>
            <li>
              <div className="frosted-glass-light">
                <span>{rebate.value}</span>
                <h3 className="currencyBadge">USDt REBATE</h3>
              </div>
            </li>
          </ul>
        </>
      ) : (
        <>
          <SVG svg="lucidao" />
          <Button className="caption" variant="text" href={stakingWebsite} newTab>
            Stake LCD to get fees rebates
          </Button>
        </>
      )}
    </div>
  );
};

export default LucidaoRebate;
