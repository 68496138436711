import React from "react";
import styled from "@emotion/styled";
import useEmblaCarousel from "embla-carousel-react";
import { Link } from "gatsby";
import { Image, Button, SlicePadding, Carousel } from "~components";
import { breakpoint } from "~utils/css";
import { useWindowDimensions } from "~hooks";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  background: ${({ backgroundColor }) => backgroundColor || `var(--color-mono-0)`};

  .featuredCollection {
    &Header {
      grid-column: 1/-1;
      margin-bottom: 3rem;

      ${breakpoint(`tablet`)} {
        text-align: center;
      }
    }
    &Cards {
      overflow: hidden;
      margin-bottom: 2rem;
    }
    &ButtonContainer {
      ${breakpoint(`tablet`)} {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

const Card = styled.div`
  flex-shrink: 0;

  .featuredCollectionCard {
    display: block;
    background: var(--color-mono-20);
    padding: 0.75rem;
    border-radius: 0.75rem;

    &ImageContainer {
      position: relative;
      overflow: hidden;
      padding-bottom: 100%;
      border-radius: 0.75rem;
      margin-bottom: 1rem;
      -webkit-mask-image: -webkit-radial-gradient(white, black); // Need for safari rounded corners ¯\_(ツ)_/¯
    }
    &Image {
      position: absolute;
      inset: 0;
      transition: 0.4s;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .featuredCollectionCardImage {
        transform: scale(1.03);
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const FeaturedCollection = ({ data: { header, products, collection, backgroundColor } }) => {
  const { isMobile } = useWindowDimensions();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
    loop: false,
    slidesToScroll: 1
  });

  return (
    <Container backgroundColor={backgroundColor?.hex}>
      <SlicePadding>
        {header && <h2 className="featuredCollectionHeader h3">{header}</h2>}

        <Carousel
          embla={{
            api: emblaApi,
            ref: emblaRef
          }}
          className="featuredCollectionCards"
          slidesPerView={isMobile ? 2.5 : 3.5}
          gap={16}
          slides={() =>
            products.map((product, i) => <FeaturedCollectionCard title={product.title} image={product.image} slug={product.slug.current} key={i} />)
          }
        />

        <div className="featuredCollectionButtonContainer">
          <Button to={`/collection/${collection.slug.current}`} fluid={isMobile} colorTheme="dark">
            View the full collection
          </Button>
        </div>
      </SlicePadding>
    </Container>
  );
};

export default FeaturedCollection;

const FeaturedCollectionCard = ({ title, image, slug }) => (
  <Card>
    <Link to={`/drops/${slug}`} className="featuredCollectionCard">
      <div className="featuredCollectionCardImageContainer">
        <Image className="featuredCollectionCardImage" image={image?.asset.gatsbyImageData} altText={image?.altText || ``} />
      </div>
      <div>{title}</div>
    </Link>
  </Card>
);
