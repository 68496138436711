import React, { useEffect, useState } from "react";
import { useApp, usePurchaseData } from "~hooks";
import { NFTCheckout, NFTIconNote, NFTOverlay, NFTNumberEntry, NFTTimeSelect } from "~components";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient, useWalletClient } from "wagmi";

const EXPIRES = { "24 hours": 86400, "7 days": 604800, "15 days": 1296000 };

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayCreateFragmentSellOrder = ({ nft }) => {
  const { setOverlayCompletionData, userData, activeOverlay } = useApp();
  const { enrichedProduct } = nft;
  const { nftData } = enrichedProduct || {};

  const { useManage0xNftAllowance, useGetFractionsBalance } = blockchainHooks();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { data: purchaseData, approved, setApproved, update, onChange, reset } = usePurchaseData();
  const { trader } = useExternalIntegrations();
  const traderSdk = trader(publicClient, walletClient);

  const [executing, setExecuting] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [valid, setValid] = useState(false);
  const { refetch } = useManage0xNftAllowance(nftData, `ERC1155`, purchaseData?.fragments, setApproved, activeOverlay === `NFTOverlayCreateFragmentSellOrder`);
  const { data: fractionBalance } = useGetFractionsBalance(nftData, userData?.address);

  useEffect(() => {
    const pricePerFragment = parseInt(purchaseData.pricePerFragment);
    const fragments = parseInt(purchaseData.fragments);
    setTotalPrice(fragments * pricePerFragment);
    setValid(purchaseData?.fragments && purchaseData?.pricePerFragment > 0 && purchaseData?.expiry && (!approved || fractionBalance > 0));
  }, [purchaseData, approved, fractionBalance]);

  const executeApproval = async () => {
    if (!nftData || executing) return;
    setExecuting(true);
    try {
      const asset = traderSdk.buildAsset(
        nftData.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress,
        `ERC1155`,
        nftData.saleId,
        purchaseData?.fragments
      );
      await traderSdk.approveSwappableAsset(asset);
    } catch (e) {
      handleError(e, setOverlayCompletionData, userData.balances.maticBalance?.value);
    } finally {
      setExecuting(false);
      refetch();
    }
  };

  const purchaseFragments = async () => {
    if (!enrichedProduct || executing) return;
    setExecuting(true);
    try {
      const asset = await traderSdk.buildAssetFromEnrichedProduct(enrichedProduct, totalPrice);
      await traderSdk.createOrder(
        enrichedProduct,
        asset.tokenAddress,
        asset.amount,
        `sell`,
        `ERC1155`,
        purchaseData?.fragments,
        Math.floor(Date.now() / 1000) + EXPIRES[purchaseData?.expiry]
      );
      setOverlayCompletionData({
        icon: `upload`,
        heading: `Your sell offer has been published`
      });
      reset();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, userData.balances.maticBalance?.value);
    } finally {
      setExecuting(false);
    }
  };

  if (!enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayCreateFragmentSellOrder" heading="Create Sell Offer" nft={nft} sidebarMode="createSellOrderFragment">
      {/* todo : max button should equal fragments available */}
      <NFTNumberEntry
        className="nftOverlayGroup"
        name="fragments"
        onChange={onChange}
        heading="1. How many Fragments would you like to sell?"
        placeholder="Enter no. of Fragments"
        max={fractionBalance ? parseInt(fractionBalance.toString()) : 1}
      />
      <NFTNumberEntry
        className="nftOverlayGroup"
        name="pricePerFragment"
        onChange={onChange}
        heading="2. How much would you like to receive per Fragment?"
        placeholder="Enter price per Fragment"
        min={1}
        withBadge
      />

      <NFTTimeSelect
        className="nftOverlayGroup"
        onSelect={(value) => update(`expiry`, value)}
        heading="3. Choose how long this sell offer will be valid for:"
      />

      <NFTCheckout
        className="nftOverlayGroup"
        heading="Your Sell Offer"
        subheading={`Total includes a ${enrichedProduct?.nftData?.makerFee} platform fee, deducted upon offer fulfillment.`}
        subheadingVisible={parseFloat(enrichedProduct?.nftData?.makerFee?.split(`%`)[0]) > 0}
        fee={enrichedProduct?.nftData?.makerFee}
        finalButtonText={fractionBalance > 0 ? `Create Sell Offer` : `Not Enough fragments`}
        nft={nft}
        data={purchaseData}
        execute={purchaseFragments}
        executeApproval={executeApproval}
        valid={valid}
        approved={approved}
        refetch={refetch}
        approveLoading={executing && !approved}
        actionLoading={executing && approved}
      />

      <div className="nftOverlayGroup">
        <NFTIconNote
          background="rgba(255, 255, 255, 0.4)"
          fontClass="caption"
          svg="alert"
          text="Kindly note, an additional 3% will be applied to your fragments as a transfer fee to the new buyer by the platform."
        />
      </div>
      <NFTIconNote
        background="rgba(255, 255, 255, 0.4)"
        fontClass="caption"
        svg="alert"
        text="Once you approve the offer, the smart contract can transfer your NFTs in any moment. Ensure correct NFTs ownership to allow the buyer's acceptance of the deal."
      />
    </NFTOverlay>
  );
};

export default NFTOverlayCreateFragmentSellOrder;
