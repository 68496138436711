import React, { useState } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { formatDateTime, getTraderOrderTimestampFromNonce } from "~utils/helpers";
import Button from "~components/Common/Button";
import Table from "~components/Common/Table";
import useApp from "~hooks/useApp";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";
import { handleError } from "~utils/error";

const ProfileItemOffers = ({ item, sellOffer, buyOffer }) => {
  const [executing, setExecuting] = useState({ cancel: false, accept: false });

  const {
    setOverlayCompletionData,
    userData: {
      balances: { maticBalance }
    },
    getConfig
  } = useApp();
  const { trader } = useExternalIntegrations();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const {
    price: { displayPrice, displayPriceWithTakerFee }
  } = useParseTraderOrder(sellOffer?.order);
  const {
    price: { displayPrice: buyOfferDisplayPrice, displayPriceWithTakerFee: buyOfferDisplayPriceWithTakerFee }
  } = useParseTraderOrder(buyOffer?.order);

  const handleCancelOffer = async () => {
    setExecuting((prev) => ({ ...prev, cancel: true }));
    const traderSdk = trader(publicClient, walletClient);
    try {
      await traderSdk.cancelOrder(sellOffer);
      setOverlayCompletionData({
        icon: `check`,
        heading: `Order cancelled`
      });
    } catch (error) {
      handleError(error, setOverlayCompletionData, maticBalance?.value);
    }
    setExecuting((prev) => ({ ...prev, cancel: false }));
  };

  const handleAcceptOffer = async () => {
    setExecuting((prev) => ({ ...prev, accept: true }));
    const traderSdk = trader(publicClient, walletClient);
    const config = await getConfig();
    try {
      await traderSdk.fillOrder(sellOffer, {}, config);
      navigate(`/profile/balances`);
    } catch (error) {
      handleError(error, setOverlayCompletionData, maticBalance?.value);
    }
    setExecuting((prev) => ({ ...prev, accept: false }));
  };

  return (
    <section className="profileItemOrders profileList">
      <h2>{item.title || item.name} Offers</h2>
      <Table>
        {sellOffer && (
          <Table.Row className="profileOrderCard profileListItem">
            <Table.Cell as="dl" className="title">
              <dt>Sell Offer</dt>
              <dd>{item.title || item.name}</dd>
            </Table.Cell>
            <Table.Cell as="dl">
              <dt>Price</dt>
              <dd>{displayPriceWithTakerFee}</dd>
            </Table.Cell>
            <Table.Cell as="dl">
              <dt>Amount</dt>
              <dd>
                {`erc721Token` in sellOffer.order
                  ? `Whole`
                  : `${sellOffer.order.erc1155TokenAmount} ${sellOffer.order.erc1155TokenAmount > 1 ? `Fragments` : `Fragment`}`}
              </dd>
            </Table.Cell>
            <Table.Cell as="dl">
              <dt>After fees</dt>
              <dd>{displayPrice}</dd>
            </Table.Cell>
            <Table.Cell as="dl">
              <dt>Expiry</dt>
              <dd className="date">{formatDateTime(parseInt(sellOffer.order?.expiry) * 1000, `DD-MM-YYYY HH:mm`)}</dd>
            </Table.Cell>
            <Table.Cell as="dl" className="status">
              <dt>{sellOffer.orderStatus?.status}</dt>
              <dd>{formatDateTime(parseInt(getTraderOrderTimestampFromNonce(sellOffer.order?.nonce)) * 1000, `DD-MM-YYYY HH:mm`)}</dd>
            </Table.Cell>
            <Button className={executing.cancel && `loading`} variant="feature" fluid onClick={handleCancelOffer}>
              Cancel Offer
            </Button>
          </Table.Row>
        )}
        {buyOffer && (
          <Table.Row className="profileOrderCard profileListItem">
            <Table.Cell as="dl" className="title">
              <dt>Best Buy Offer</dt>
              <dd>{item.title || item.name}</dd>
            </Table.Cell>
            <Table.Cell as="dl">
              <dt>Price</dt>
              <dd>{buyOfferDisplayPriceWithTakerFee}</dd>
            </Table.Cell>
            <Table.Cell as="dl">
              <dt>Amount</dt>
              <dd>
                {`erc721Token` in buyOffer.order
                  ? `Whole`
                  : `${buyOffer.order.erc1155TokenAmount} ${buyOffer.order.erc1155TokenAmount > 1 ? `Fragments` : `Fragment`}`}
              </dd>
            </Table.Cell>
            <Table.Cell as="dl">
              <dt>After fees</dt>
              <dd>{buyOfferDisplayPrice}</dd>
            </Table.Cell>
            <Table.Cell as="dl">
              <dt>Expiry</dt>
              <dd className="date">{formatDateTime(parseInt(buyOffer.order?.expiry) * 1000, `DD-MM-YYYY HH:mm`)}</dd>
            </Table.Cell>
            <Table.Cell as="dl" className="status">
              <dt>
                <span>{buyOffer.orderStatus?.status}</span>
              </dt>
              <dd>{formatDateTime(parseInt(getTraderOrderTimestampFromNonce(buyOffer.order?.nonce)) * 1000, `DD-MM-YYYY HH:mm`)}</dd>
            </Table.Cell>
            <Button className={executing.accept && `loading`} variant="feature" fluid onClick={handleAcceptOffer}>
              Accept Offer
            </Button>
          </Table.Row>
        )}
      </Table>
    </section>
  );
};

export default ProfileItemOffers;
