import React, { useEffect, useState } from "react";
import { useApp, usePurchaseData } from "~hooks";
import { NFTCheckout, NFTIconNote, NFTOverlay, NFTNumberEntry, NFTTimeSelect } from "~components";
import { truncateAddress } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient, useWalletClient } from "wagmi";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayPurchaseFragmentFromOwner = ({ nft, activeOwner }) => {
  const expires = { "24 hours": 86400, "7 days": 604800, "15 days": 1296000 };
  // ---------------------------------------------------------------------------
  // imports / hooks

  const {
    setOverlayCompletionData,
    activeOverlay,
    userData: {
      balances: { maticBalance }
    }
  } = useApp();
  const { enrichedProduct } = nft;

  const { useManage0xTokenAllowance, useGetFractionsBalance, useGetPurchaseTokenBalance } = blockchainHooks();

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { data: purchaseData, approved, setApproved, update, onChange, reset } = usePurchaseData();

  const { trader } = useExternalIntegrations();
  const traderSdk = trader(publicClient, walletClient);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [valid, setValid] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const executeApproval = async () => {
    if (!enrichedProduct || executing) {
      return () => {};
    }

    setExecuting(true);

    try {
      const asset = await traderSdk.buildAssetFromEnrichedProduct(enrichedProduct, totalPrice.toString());
      const { takerFee } = await traderSdk.getFees(enrichedProduct.product.identifier, BigInt(asset.amount));
      asset.amount += ((BigInt(asset.amount) * takerFee) / 100n).toString();
      await traderSdk.approveSwappableAsset(asset);
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);

    return null;
  };

  const purchaseFragments = async () => {
    if (!enrichedProduct || executing) {
      return () => {};
    }

    setExecuting(true);

    const asset = await traderSdk.buildAssetFromEnrichedProduct(enrichedProduct, totalPrice?.toString() || 0);

    try {
      await traderSdk.createOrder(
        enrichedProduct,
        asset.tokenAddress,
        asset.amount,
        `buy`,
        `ERC1155`,
        purchaseData?.fragments,
        Math.floor(Date.now() / 1000) + expires[purchaseData?.expiry],
        activeOwner?.address
      );

      setOverlayCompletionData({
        heading: `Your offer has been sent`,
        body: `Be sure to always have the offered amount to allow the transfer when the deal is accepted.`
      });

      reset();
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const pricePerFragment = parseInt(purchaseData.pricePerFragment);
    const fragments = parseInt(purchaseData.fragments);

    setTotalPrice(fragments * pricePerFragment);
  }, [purchaseData?.pricePerFragment, purchaseData?.fragments]);

  useEffect(() => {
    if (activeOwner) {
      update(`taker`, truncateAddress(activeOwner?.address));
    }
  }, [activeOwner?.address]);

  useEffect(() => {
    setValid(purchaseData?.fragments && purchaseData?.pricePerFragment > 0 && purchaseData?.expiry);
  }, [purchaseData?.fragments, purchaseData?.pricePerFragment, purchaseData?.expiry]);

  const { refetch } = useManage0xTokenAllowance(
    enrichedProduct,
    totalPrice,
    setApproved,
    true,
    {},
    false,
    activeOverlay === `NFTOverlayPurchaseFragmentFromOwner`
  );

  const { data: maxFractionsAmount } = useGetFractionsBalance(enrichedProduct?.nftData, activeOwner?.address);

  const { data: tokenBalance } = useGetPurchaseTokenBalance(enrichedProduct?.nftData);

  // ---------------------------------------------------------------------------
  // render

  if (!enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayPurchaseFragmentFromOwner" heading="Make an Offer" nft={nft} sidebarMode="fragmentFromOwner">
      <NFTNumberEntry
        className="nftOverlayGroup"
        name="fragments"
        onChange={onChange}
        heading="1. How many Fragments would you like to purchase?"
        placeholder="Enter no. of Fragments"
        min={1}
        max={activeOwner ? parseInt(maxFractionsAmount?.toString()) : enrichedProduct?.product?.numberOfFragments}
      />

      {/* todo (validation): max should equal fragments available */}
      <NFTNumberEntry
        className="nftOverlayGroup"
        name="pricePerFragment"
        onChange={onChange}
        heading="2. How much would you like to offer per Fragment?"
        placeholder="Enter price per Fragment"
        min={0}
        max={purchaseData?.fragments ? Number(tokenBalance?.formatted) / Number(purchaseData.fragments) : undefined}
        withBadge
      />

      {/* todo (validation): (usdt per fraction * fractions wanted) < wallet balance */}
      <NFTTimeSelect
        className="nftOverlayGroup"
        onSelect={(value) => {
          update(`expiry`, value);
        }}
        heading="3. Choose how long this offer will be valid for:"
      />

      <NFTCheckout
        className="nftOverlayGroup"
        heading="Your Offer"
        subheading={`Price includes ${enrichedProduct?.nftData?.makerFee} platform fee.`}
        subheadingVisible={parseFloat(enrichedProduct?.nftData?.makerFee.split(`%`)[0]) > 0}
        fee={enrichedProduct?.nftData?.makerFee}
        finalButtonText="Make an Offer"
        nft={nft}
        data={purchaseData}
        execute={purchaseFragments}
        executeApproval={executeApproval}
        valid={valid}
        approved={approved}
        refetch={refetch}
        approveLoading={executing && !approved}
        actionLoading={executing && approved}
      />

      <NFTIconNote
        background="rgba(255, 255, 255, 0.4)"
        fontClass="caption"
        svg="alert"
        text="Once you approve the offer, the smart contract can withdraw your USDt in any moment. Ensure sufficient funds to allow the owner's acceptance of the deal."
      />
    </NFTOverlay>
  );
};

export default NFTOverlayPurchaseFragmentFromOwner;
