import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useApp } from "~hooks";
import { NFTIconNote, NFTOverlay, PriceWidget, NFTCheckout } from "~components";
import { genericErrorCallback, thousandCommas } from "~utils/helpers";
import { usePublicClient, useWalletClient } from "wagmi";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import usePurchaseData from "~hooks/usePurchaseData";
import { handleError } from "~utils/error";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayPurchase = ({ nft }) => {
  const { enrichedProduct } = nft;
  const {
    setActiveOverlay,
    activeOverlay,
    setOverlayCompletionData,
    userData: {
      balances: { maticBalance }
    },
    getConfig
  } = useApp();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { useManage0xTokenAllowance } = blockchainHooks();
  const { trader } = useExternalIntegrations();

  const { approved: purchaseApproved, setApproved, data: purchaseData, updateAll, reset } = usePurchaseData();

  const [executing, setExecuting] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [exchangeProxyAddress, setExchangeProxyAddress] = useState();

  const executeApproval = async () => {
    if (executing || !enrichedProduct?.nftData) {
      return;
    }

    setExecuting(true);

    const traderSdk = trader(publicClient, walletClient);
    const price = enrichedProduct.nftData.price.usdAmount.toString();

    try {
      const asset = await traderSdk.buildAssetFromEnrichedProduct(enrichedProduct, price || 0);
      const { takerFee } = await traderSdk.getFees(enrichedProduct.product.identifier, BigInt(asset.amount));
      asset.amount += ((BigInt(asset.amount) * takerFee) / 100n).toString();
      await traderSdk.approveSwappableAsset(asset, { exchangeProxyAddress });
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);
  };

  const purchase = async () => {
    if (executing || !enrichedProduct) {
      return;
    }

    setExecuting(true);

    const traderSdk = trader(publicClient, walletClient);
    const config = await getConfig();

    try {
      await traderSdk.fillOrderByProduct(enrichedProduct, {}, config);

      reset();
      setActiveOverlay(null);
      navigate(`/purchase-confirmation/${enrichedProduct?.product?.identifier}`);
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }
    setExecuting(false);
  };

  const { refetch } = useManage0xTokenAllowance(
    enrichedProduct,
    purchaseData?.price,
    setApproved,
    true,
    { exchangeProxyAddress },
    forceUpdate,
    activeOverlay === `NFTOverlayPurchase`
  );

  useEffect(() => {
    if (activeOverlay && activeOverlay === `NFTOverlayPurchase`) {
      updateAll({ price: enrichedProduct?.product?.price?.usdAmount });
      setForceUpdate(!forceUpdate);

      const getExchangeProxyAddress = async () => {
        setExchangeProxyAddress((await getConfig()).tradeCheckerAddress);
      };

      getExchangeProxyAddress().catch(genericErrorCallback);
    }
  }, [enrichedProduct?.product?.price?.usdAmount, activeOverlay]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <NFTOverlay id="NFTOverlayPurchase" heading="Buy Whole Item" nft={nft}>
      {enrichedProduct && (
        <>
          <div className="nftEntry checkout">
            <div className="summary">
              <h4 className="caption">Collectible Price</h4>
              <div className="summaryPrice">
                <PriceWidget
                  color="white"
                  displayPrice={thousandCommas(
                    enrichedProduct?.nftData?.price?.usdAmount +
                      enrichedProduct?.nftData?.price?.usdAmount * parseFloat(parseInt(nft?.enrichedProduct?.nftData?.makerFee?.split(`%`)[0]) / 100)
                  )}
                  fontClass="h2"
                />
              </div>
            </div>
            <p className="caption">The price includes fees for maintaining, securely storing, and insuring the collectible for a period of 5 years.</p>
          </div>
          <NFTCheckout
            className="nftOverlayGroup"
            subheading={`Price includes ${enrichedProduct?.nftData?.takerFee} platform fee.`}
            subheadingVisible={parseFloat(enrichedProduct?.nftData?.takerFee.split(`%`)[0]) > 0}
            fee={enrichedProduct?.nftData?.takerFee}
            finalButtonText="Purchase"
            summaryActive={false}
            nft={nft}
            data={purchaseData}
            execute={purchase}
            executeApproval={executeApproval}
            excludeKeys={[`expiry`]}
            approved={purchaseApproved}
            valid={parseInt(purchaseData?.price) > 0}
            refetch={refetch}
            approveLoading={executing && !purchaseApproved}
            actionLoading={executing && purchaseApproved}
          />
        </>
      )}

      <div className="nftOverlayGroup">
        <NFTIconNote
          background="rgba(255, 255, 255, 0.4)"
          fontClass="caption"
          svg="shield"
          text="ALTR items are securely stored and insured in certified third-party Free Port facilities in Switzerland. For more information, please visit our website's Product Storage and Insurance section."
        />
      </div>

      {!purchaseApproved && (
        <div className="nftOverlayGroup">
          <NFTIconNote
            background="rgba(255, 255, 255, 0.4)"
            fontClass="caption"
            svg="alert"
            text="By clicking this button, you are authorizing ALTR's smart contract to initiate a blockchain transaction and process funds to the seller on your behalf."
          />
        </div>
      )}
    </NFTOverlay>
  );
};

export default NFTOverlayPurchase;
