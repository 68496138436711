import React from "react";
import { Link } from "gatsby";

/** ============================================================================
 * @component
 * For use with the linkOptions sanity object
 */
const CtaLink = ({ cta, children, className }) => {
  if (!cta) return null;

  const { externalLink, globalLink, internalLink } = cta;

  if (cta.type === `none`) return null;

  if (cta.type === `linkInternal`) {
    const { reference } = internalLink;

    if (reference._type === `page`) {
      return (
        <Link className={className} to={`/${reference.slug.current}`}>
          {children || internalLink.text}
        </Link>
      );
    }

    if (reference._type === `event`) {
      return (
        <Link className={className} to={`/events/${reference.slug.current}`}>
          {children || internalLink.text}
        </Link>
      );
    }

    if (reference._type === `product`) {
      return (
        <Link className={className} to={`/drops/${reference.slug.current}`}>
          {children || internalLink.text}
        </Link>
      );
    }

    if (reference._type === `raffle`) {
      return (
        <Link className={className} to={`/raffles/${reference.slug.current}`}>
          {children || internalLink.text}
        </Link>
      );
    }
  }

  if (cta.type === `linkExternal`) {
    return (
      <a className={className} href={externalLink.url} target={externalLink.newTab ? `_blank` : undefined}>
        {children || externalLink.text}
      </a>
    );
  }

  if (cta.type === `globalLink`) {
    return (
      <a className={className} href={globalLink.link.url} target={globalLink.newTab ? `_blank` : undefined}>
        {children || globalLink.link.text}
      </a>
    );
  }

  return null;
};

export default CtaLink;
