import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Button, Grid, ProductCard } from "~components";
import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  position: relative;
  padding: 3rem 0 1rem;
  overflow: hidden;
  background: ${({ background }) => `${background}`};
  color: ${({ color }) => `${color}`};

  ${breakpoint(`large-tablet`)} {
    padding: 10.5rem 0 3.75rem;
  }
`;

const Header = styled.div`
  grid-column: -1 / 1;
  position: relative;
  margin-bottom: 3.75rem;
`;

const Heading = styled.h1`
  margin-bottom: 1rem;
`;

const FilterBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const FilterHeading = styled.h2`
  margin-right: 0.75rem;
`;

const FilterButton = styled(Button)`
  height: 2.625rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 0.75rem;
`;

const CardContainer = styled.li`
  grid-column: -1 / 1;

  position: relative;
  display: block;
  margin-bottom: 1.5rem;

  opacity: 0;

  animation: var(--animation-appear-down);
  animation-delay: ${({ index }) => `${0.125 * index}s`};

  ${breakpoint(`tablet`)} {
    grid-column: span 4 / span 4;
    margin-bottom: 2.5rem;
  }
`;

/** ============================================================================
 * @component
 */
const ProductGrid = ({ data: { backgroundColour, fontColour, cards } }) => {
  const [collections, setCollections] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [filteredCards, setFilteredCards] = useState([]);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (cards?.length < 1) {
      return;
    }

    const allCollections = [...new Set(cards.map((card) => card?.collection?.title))];

    setCollections(allCollections);
  }, [cards?.length]);

  useEffect(() => {
    if (cards?.length < 1) return null;

    if (!activeFilter) return setFilteredCards(cards);

    const filtered = cards.filter((card) => card?.collection?.title === activeFilter);
    return setFilteredCards(filtered);
  }, [cards?.length, activeFilter]);

  // ---------------------------------------------------------------------------
  // render

  if (!cards?.[0]) {
    return <></>;
  }

  return (
    <Container className="container" background={backgroundColour?.hex || `#ffffff`} color={fontColour?.hex || `#000000`}>
      <Grid>
        <Header>
          <Heading className="h2">All Drops</Heading>

          <FilterBar>
            <FilterHeading className="caption">Collection</FilterHeading>

            {/* todo: overflow-x on mobiles */}

            <FilterButton color="black" onClick={() => setActiveFilter(null)} transparent={activeFilter !== null}>
              <span className="button-text">All</span>
            </FilterButton>

            {collections?.[0] &&
              collections.map((title) => (
                <FilterButton key={`filter-button-${title}`} color="black" onClick={() => setActiveFilter(title)} transparent={activeFilter !== title}>
                  <span className="button-text">{title}</span>
                </FilterButton>
              ))}
          </FilterBar>
        </Header>
      </Grid>

      <Grid node="ul">
        {filteredCards.map((card, index) => (
          <CardContainer key={card.id} index={index}>
            <ProductCard data={card} />
          </CardContainer>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductGrid;
