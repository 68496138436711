import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { TARGET_CHAIN } from "~utils/helpers";
import cn from "classnames";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import Button from "~components/Common/Button";
import MarketplaceItemActionDrawer from "../../MarketplaceItemActionDrawer";
import { useMarketplaceItemContext } from "../../MarketplaceItemContext";
import PriceWidget from "~components/Product/PriceWidget";
import useWindowDimensions from "~hooks/useWindowDimensions";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { handleError } from "~utils/error";
import useApp from "~hooks/useApp";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";
import SwitchChainButton from "~components/Common/SwitchChainButton";
import LucidaoRebate from "~components/Common/LucidaoRebate";

const SellAction = ({ product, sellOrder, isOracleSeller }) => {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [executing, setExecuting] = useState({ cancel: false, sellNow: false });

  const { selectedOffer } = useMarketplaceItemContext();
  const { isMobile, isTablet } = useWindowDimensions();
  const { trader } = useExternalIntegrations();
  const {
    userData: {
      balances: { maticBalance }
    },
    setOverlayCompletionData,
    forceUpdate,
    update,
    getConfig
  } = useApp();
  const { chain } = useAccount();

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const {
    price: { displayPrice, displayPriceWithTakerFee, takerFeePercentage },
    eta,
    calculatePercentageDifference
  } = useParseTraderOrder(selectedOffer?.order);

  const {
    price: { displayPrice: sellOrderDisplayPrice, displayPriceWithMakerFee: sellOrderDisplayPriceWithMakerFee, makerFeePercentage },
    eta: sellOrderEta
  } = useParseTraderOrder(sellOrder.order);

  if ((!selectedOffer?.order || isOracleSeller) && !(isMobile || isTablet)) return;

  const floorDifference = calculatePercentageDifference(selectedOffer?.order, sellOrder.order);

  const handleCancelOrder = async () => {
    setExecuting((prev) => ({ ...prev, cancel: true }));
    const traderSdk = trader(publicClient, walletClient);
    try {
      await traderSdk.cancelOrder(sellOrder);
      setOverlayCompletionData({
        icon: `check`,
        heading: `Order cancelled`
      });
    } catch (error) {
      handleError(error, setOverlayCompletionData, maticBalance?.value);
      console.error(error);
    }
    setExecuting((prev) => ({ ...prev, cancel: false }));
  };

  const handleSellNow = async () => {
    setExecuting((prev) => ({ ...prev, sellNow: true }));
    const traderSdk = trader(publicClient, walletClient);
    const config = await getConfig();

    try {
      await traderSdk.fillOrder(selectedOffer, {}, config);
      navigate(`/profile/balances/`);
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting((prev) => ({ ...prev, sellNow: false }));
  };

  return (
    <MarketplaceItemActionDrawer>
      <section className="sell">
        <h3>Sell</h3>
        {selectedOffer.order ? (
          <>
            <dl>
              <dt>Listing price</dt>
              <dd>
                <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPriceWithTakerFee} />
              </dd>
            </dl>
            <dl>
              <dt>After fees ({takerFeePercentage}%)</dt>
              <dd>
                <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPrice} />
              </dd>
            </dl>
            <dl>
              <dt>Floor difference</dt>
              <dd>{floorDifference.startsWith(`-`) ? `${floorDifference.slice(1)} below` : `${floorDifference} above`}</dd>
            </dl>
            <dl>
              <dt>Expiration</dt>
              <dd>{eta}</dd>
            </dl>

            <LucidaoRebate nftData={product?.nftData} order={sellOrder} queryEnabled={!!product?.nftData?.identifier && !!sellOrder?.order?.nonce} />

            <label>
              <input name="accepted" onChange={() => setIsTermsChecked((prev) => !prev)} type="checkbox" />
              <span className="caption">
                Accept <Link to="/terms-and-conditions">terms and conditions</Link>
              </span>
            </label>
            {TARGET_CHAIN?.id !== chain?.id ? (
              <SwitchChainButton className="singleButton" fluid variant="feature" colorTheme="light" onSuccess={() => forceUpdate(!update)} />
            ) : (
              <>
                {isMobile && (
                  <Button
                    className={cn(executing.cancel && `loading`)}
                    disabled={executing.cancel || executing.sellNow}
                    variant="primaryTall"
                    fluid
                    onClick={handleCancelOrder}
                  >
                    Cancel Your Sell Offer
                  </Button>
                )}
                <Button
                  className={cn(executing.sellNow && `loading`, !isMobile && `singleButton`)}
                  disabled={!isTermsChecked || executing.cancel || executing.sellNow}
                  variant="feature"
                  fluid
                  onClick={handleSellNow}
                >
                  Sell Now
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <dl>
              <dt>Price</dt>
              <dd>
                <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={sellOrderDisplayPriceWithMakerFee} />
              </dd>
            </dl>
            <dl>
              <dt>After fees ({makerFeePercentage}%)</dt>
              <dd>
                <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={sellOrderDisplayPrice} />
              </dd>
            </dl>
            <dl>
              <dt>Expiration</dt>
              <dd>{sellOrderEta}</dd>
            </dl>

            <LucidaoRebate nftData={product?.nftData} order={sellOrder} queryEnabled={!!product?.nftData?.identifier && !!sellOrder?.order?.nonce} />

            {TARGET_CHAIN?.id !== chain?.id ? (
              <SwitchChainButton className="singleButton" fluid variant="feature" colorTheme="light" onSuccess={() => forceUpdate(!update)} />
            ) : (
              <Button
                className={cn(`singleButton`, executing.cancel && `loading`)}
                disabled={executing.cancel}
                variant="primaryTall"
                fluid
                onClick={handleCancelOrder}
              >
                Cancel Offer
              </Button>
            )}
          </>
        )}
      </section>
    </MarketplaceItemActionDrawer>
  );
};

export default SellAction;
